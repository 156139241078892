<script lang="ts" setup>
import TillhubLogo from '@/assets/TillhubLogo.svg'
import { useAuthStore } from '@/stores/useAuthStore'
import { ThIcon, ThMenu, ThMenuButton, ThMenuItem, ThMenuItems } from '@/components/ui'
import { useI18n } from '@/plugins/i18n'
import { useBranchesStore } from '@/stores/useBranchesStore'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const router = useRouter()
const authStore = useAuthStore()
const branchesStore = useBranchesStore()
const { selectedBranch, branchesCount } = storeToRefs(branchesStore)

// This is intentionally not reactive, because otherwise it'll disappear
// when the logout button is clicked and the user is set to null
const email = authStore.user?.email

async function onLogout() {
  // Order matters here, first need to clear user so Login Guard
  // does not redirect user to homepage. We want to clear the branch after he has navigated
  // to the new page because rendered components may rely on branch.id, we dont want that
  // to result in excessive network requests or type errors
  authStore.logout()
  await router.replace({ name: 'login' })
  branchesStore.clearSelectedBranch()
}

function onChangeBranch() {
  router.push({ name: 'choose_branch' })
}
</script>

<template>
  <header class="relative z-10 flex md:h-[5.625rem] text-blue-900 bg-gray-100 shadow">
    <figure class="py-4 px-2 md:px-8 flex items-center justify-center border-r border-gray-400">
      <TillhubLogo class="w-16 md:w-[6.625rem]" />
    </figure>

    <div class="flex-1 px-2 md:px-8 flex items-center justify-between overflow-hidden">
      <h3 class="font-semibold text-base md:text-[1.125rem]">
        {{ t('calendar.title') }}
      </h3>

      <div>
        <ThMenu>
          <ThMenuButton>
            <button
              type="button"
              class="flex items-center justify-end text-right text-sm font-semibold leading-none text-blue-900"
            >
              <span class="flex flex-col items-end">
                <span>{{ email }}</span>
                <span class="text-gray-600">{{ selectedBranch.name }}</span>
              </span>

              <ThIcon is="ChevronDown" class="w-3.5 ml-3" />
            </button>
          </ThMenuButton>

          <ThMenuItems>
            <ThMenuItem
              v-if="branchesCount > 0"
              icon="Refresh"
              :label="t('calendar.header.actions.change_branch')"
              @click="onChangeBranch"
            />
            <ThMenuItem
              icon="SignOut"
              :label="t('calendar.header.actions.log_out')"
              @click="onLogout"
            />
          </ThMenuItems>
        </ThMenu>
      </div>
    </div>
  </header>
</template>
